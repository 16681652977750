import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/style/main.css'
import VueQuillEditor from 'vue-quill-editor'
import * as filters from './filters'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import edit from '@/components/input/index.js'
require('./directive')
Vue.use(ElementUI)

Vue.use(edit)

const url = 'https://mis.firstknow.com/api/'
Vue.use(VueQuillEditor /* { default global options } */)
Vue.config.productionTip = false
Vue.prototype.GLOBAL = {
  downloadTC: 'https://www.firstknow.club:8082',
  pageSize: 15,
  SIZE_OF_INPUTBOX: 'small',
  host: process.env.NODE_ENV === 'production' ? url : 'http://localhost:8080/api/'
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
router.beforeEach((to, from, next) => {
  if (to.meta.title && to.meta.title.indexOf('客户') === -1) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
