<template lang="pug">
  .edit-input
    el-row
      el-col(:span="20")
        el-date-picker.wd-100(
          :type="type"
          placeholder="选择日期"
          v-model="newValue"
          style="width: 100%;"
          :value-format="format"
          :disabled="isEditor"
          :size="GLOBAL.SIZE_OF_INPUTBOX")
      el-col(:span="1")
        .cursor(v-if="exit")
          i.el-icon-edit-outline(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="isEditor" @click="isAllow")
          i.el-icon-circle-check(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="!isEditor" @click="save")

</template>

<script>
export default {
  name: 'EditDate',
  props: {
    value: {},
    isEditorInput: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'date'
    },
    exit: {
      type: Boolean,
      default: true
    },
    keyName: {
      type: String
    }
  },
  data () {
    return {
      isEditor: true,
      beforeUpdate: true,
      format: this.type === 'month' ? 'yyyy-MM' : (this.type === 'datetime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd')
    }
  },
  created () {
    this.isEditor = this.isEditorInput
  },
  computed: {
    newValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    isEditorInput: {
      handler: function (val) {
        if (val) {
          this.isEditor = val
        }
      },
      immediate: true
    },
    exit: {
      handler: function (val) {
        if (!val) this.isEditor = val
      },
      immediate: true
    }
  },
  methods: {
    isAllow () {
      this.$emit('update:isEditorInput', false)
      this.isEditor = !this.isEditor
      this.$emit('editing')
    },
    save () {
      this.$emit('save', this.keyName, this.newValue, run => {
        if (this.beforeUpdate) {
          this.isEditor = !this.isEditor
        }
      })
    },
    change (val) {
      this.isEditor = val
    }
  }
}
</script>

<style scoped>
.el-icon-edit-outline {
  padding: 7px;
}
.cursor {
  cursor: pointer;
  color: #666666;
  font-size: 1.5em;
}

/* disable 后改变字体颜色 */
:deep() input:disabled,:deep() textarea:disabled {
  opacity: 0.7;
  -webkit-text-fill-color: black;
}
</style>
