import editInput from './Input'
import editNumber from './Number'
import editSelect from './Select'
import editAutocomplete from './autocompolete'
import editDate from './Date'
import editCheckbox from './CheckBox'
import editRadio from './Radio'
import editRate from './Rate'

const components = [
  editInput,
  editNumber,
  editSelect,
  editAutocomplete,
  editDate,
  editCheckbox,
  editRadio,
  editRate
]

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default install
