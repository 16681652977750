import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: '壹信MIS'
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: '壹信MIS'
    }
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Login.vue'),
    meta: {
      title: '壹信MIS'
    }
  },
  {
    path: '/home/getProject',
    name: 'GetProject',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: '壹信MIS'
    }
  },
  {
    path: '/ProjectList',
    name: 'ProjectList',
    component: () => import('@/pages/ProjectList.vue'),
    meta: {
      title: '壹信-项目'
    }
  },
  {
    path: '/Project',
    name: 'Project',
    component: () => import('@/views/layout/Layout.vue'),
    meta: {
      title: '壹信-项目'
    },
    children: [
      {
        path: 'Info',
        name: 'ProjectInfo',
        component: () => import('@/views/project/Info.vue'),
        meta: {
          title: '壹信-项目'
        }
      },
      {
        path: 'LinkMan',
        name: 'ProjectLinkMan',
        component: () => import('@/views/project/LinkMan.vue'),
        meta: {
          title: '壹信-项目'
        }
      },
      {
        path: 'Contract',
        name: 'ProjectContract',
        component: () => import('@/views/project/Contract.vue'),
        meta: {
          title: '壹信-项目'
        }
      },
      {
        path: 'Task',
        name: 'Task',
        component: () => import('@/views/project/Task.vue'),
        meta: {
          title: '壹信-项目'
        }
      },
      {
        path: 'Admin',
        name: 'ProjectAdmin',
        component: () => import('@/views/project/Admin.vue'),
        meta: {
          title: '壹信-项目'
        }
      }
    ]
  },
  {
    path: '/ClientList',
    name: 'ClientList',
    component: () => import('@/pages/ClientList.vue'),
    meta: {
      title: '壹信-客户'
    }
  },
  {
    path: '/AllClient',
    name: 'AllClient',
    component: () => import('@/pages/allClient.vue'),
    meta: {
      title: '壹信-客户'
    }
  },
  {
    path: '/Client',
    name: 'Client',
    component: () => import('@/views/layout/Layout.vue'),
    meta: {
      title: '壹信-客户'
    },
    children: [
      {
        path: 'Info',
        name: 'ClientInfo',
        component: () => import('@/views/client/Info'),
        meta: {
          title: '壹信-客户'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      },
      {
        path: 'Contract',
        name: 'ClientContract',
        component: () => import('@/views/client/component/contract'),
        meta: {
          title: '壹信-客户'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      },
      {
        path: 'Linkman',
        name: 'ClientLinkman',
        component: () => import('@/views/client/Linkman'),
        meta: {
          title: '壹信-客户'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      },
      {
        path: 'Project',
        name: 'ClientProject',
        component: () => import('@/views/client/Project'),
        meta: {
          title: '壹信-客户'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      }
    ]
  },
  {
    path: '/TaskList',
    name: 'TaskList',
    component: () => import('@/pages/TaskList/TaskList.vue'),
    meta: {
      title: '壹信-项目'
    }
  },
  {
    path: '/hSign',
    name: 'hSign',
    component: () => import('@/pages/System/hSign.vue'),
    meta: {
      title: '壹信MIS'
    }
  },
  {
    path: '/ExpertList',
    name: 'ExpertList',
    component: () => import('@/pages/ExpertList.vue'),
    meta: {
      title: '壹信-专家'
    }
  },
  {
    path: '/Expert',
    name: 'Expert',
    component: () => import('@/views/layout/Layout.vue'),
    meta: {
      title: '壹信-专家'
    },
    children: [
      {
        path: 'Info',
        name: 'ExpertInfo',
        component: () => import('@/views/expert/Info'),
        meta: {
          title: '壹信-专家'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      },
      {
        path: 'Bank',
        name: 'ExpertBank',
        component: () => import('@/views/expert/Bank'),
        meta: {
          title: '壹信-专家'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      },
      {
        path: 'Task',
        name: 'ExpertTask',
        component: () => import('@/views/expert/Task'),
        meta: {
          title: '壹信-专家'
        }
        // meta: { title: '报告管理', icon: 'fa fa-file-text', fa: 'fas' }
      }
    ]
  },
  {
    path: '/ExpertPay',
    name: 'ExpertPay',
    component: () => import('../pages/Finance/ExpertPay.vue'),
    meta: {
      title: '壹信-财务'
    }
  },
  {
    path: '/Bill',
    name: 'Bill',
    component: () => import('../pages/Finance/Bill.vue'),
    meta: {
      title: '壹信-财务'
    }
  },
  {
    path: '/ClientCheck',
    name: 'ClientCheck',
    component: () => import('../pages/Finance/ClientCheck.vue'),
    meta: {
      title: '壹信-财务'
    }
  },
  {
    path: '/emailTemplate',
    name: 'emailTemplate',
    component: () => import('../pages/System/emailTemplate.vue'),
    meta: {
      title: '壹信MIS'
    }
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem('passport')) {
//     next()
//   } else {
//     if (to.path === '/login') {
//       next()
//     } else {
//       console.log('to', to)
//       next('/login')
//     }
//   }
// })

export default router
