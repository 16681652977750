/* eslint-disable */

//客户类型
const Own_TYPE = [
  {
    value: 0,
    label: "北京分公司"
  },
  {
    value: 1,
    label: "上海分公司"
  },
  {
    value: 2,
    label: "南京分公司"
  },
  {
    value: 3,
    label: "深圳分公司"
  },
  {
    value: 4,
    label: "香港分公司"
  }
]

const CLIENT_TYPE = [
  {
    value: 0,
    label: "CF"
  },
  {
    value: 1,
    label: "PE"
  },
  {
    value: 2,
    label: "HF"
  },
  {
    value: 3,
    label: "MF"
  },
  {
    value: 4,
    label: "Enterprise"
  },
  {
    value: 5,
    label: "Other"
  }
]

//客户状态
const CLIENT_STATUS = [
  {
    value: 0,
    label: "试用"
  },
  {
    value: 1,
    label: "潜在"
  },
  {
    value: 2,
    label: "正式"
  },
  {
    value: 3,
    label: "流失"
  }
]

//联系人头衔
const LINKMAN_CHARACTER = [
  {
    value: 0,
    label: "老板"
  },
  {
    value: 1,
    label: "总监"
  },
  {
    value: 2,
    label: "经理"
  },
  {
    value: 3,
    label: "助理"
  }
]

//联系人角色

const LINKMAN_ROLE = [
  {
    value: "is_default",
    label: "首要联系人"
  },
  {
    value: "is_admin",
    label: "负责人"
  },
  {
    value: "is_law",
    label: "法务"
  },
  {
    value: "is_finance",
    label: "财务"
  }
]

/***************/
//合同
/***************/

//合同类型
const CONTRACT_TYPE = [
  {
    value: 0,
    label: "咨询"
  },
  {
    value: 1,
    label: "调查"
  },
  {
    value: 2,
    label: "其他"
  }
]

const CLIENT_CONTRACT_TYPE = [
  {
    value: 0,
    label: "框架"
  },
  {
    value: 1,
    label: "项目"
  },
  {
    value: 2,
    label: "其他"
  }
]

const CONTRACT_STATUS = [
  {
    value: 0,
    label: "待签"
  },
  {
    value: 1,
    label: "生效"
  },
  {
    value: 2,
    label: "失效"
  }
]

/***************/
//项目
/***************/

//货币

const CURRENCY = [
  {
    value: 0,
    label: "RMB"
  },
  {
    value: 1,
    label: "USD"
  },
  {
    value: 2,
    label: "JPY"
  },
  {
    value: 3,
    label: "EUR"
  },
  {
    value: 4,
    label: "TWD"
  },
  {
    value: 5,
    label: "HKD"
  },
  {
    value: 6,
    label: "MOP"
  }
]

//项目状态

const PROJECT_STATUS = [
  {
    value: 0,
    label: "执行"
  },
  {
    value: 1,
    label: "暂缓"
  },
  {
    value: 2,
    label: "完成"
  },
  {
    value: 3,
    label: "撤销"
  }
]

//项目类型

const PROJECT_TYPE = [
  {
    value: 0,
    label: "访谈"
  },
  {
    value: 1,
    label: "调研"
  },
  {
    value: 2,
    label: "其他"
  }
]

/***************/
//项目
/***************/

//访谈方式

const CONVERSATION_TYPE = [
  {
    value: 0,
    label: "电话"
  },
  {
    value: 1,
    label: "面谈"
  },
  {
    value: 2,
    label: "数据"
  },
  {
    value: 3,
    label: "调研"
  },
  {
    value: 4,
    label: "其他"
  }
]

/***************/
//专家
/***************/

//来源

const EXPERT_SOURCE = [
  {
    value: 0,
    label: "linkedin"
  },
  {
    value: 1,
    label: "百度"
  },
  {
    value: 2,
    label: "谷歌"
  },
  {
    value: 3,
    label: "官网"
  },
  {
    value: 4,
    label: "展会名片"
  },
  {
    value: 5,
    label: "专家推荐/内推"
  },
  {
    value: 6,
    label: "简历网站"
  },
  {
    value: 7,
    label: "其他"
  },
  {
    value: 100,
    label: "待验证"
  }
]

//联系方式

const EXPERT_CONTACT = [
  {
    value: 0,
    label: "手机"
  },
  {
    value: 1,
    label: "微信"
  },
  {
    value: 2,
    label: "Email"
  },
  {
    value: 3,
    label: "座机"
  },
  {
    value: 4,
    label: "QQ"
  },
  {
    value: 5,
    label: "Skype"
  }
]

const GENDER = [
  {
    value: 0,
    label: "男"
  },
  {
    value: 1,
    label: "女"
  },
  {
    value: 2,
    label: "未知"
  }
]

/***************/
//银行账户
/***************/

//证件类型

const BANK_ID_TYPE = [
  {
    value: 0,
    label: "居民身份证"
  },
  {
    value: 1,
    label: "护照"
  },
  {
    value: 2,
    label: "香港身份证"
  },
  {
    value: 3,
    label: "澳门身份证"
  },
  {
    value: 4,
    label: "台胞证"
  },
  {
    value: 5,
    label: "港澳通行证"
  },
  {
    value: 6,
    label: "其他"
  }
]
const BANK_ACCOUNT_TYPE = [
  {
    value: 0,
    label: "本人"
  },
  {
    value: 1,
    label: "亲人"
  },
  {
    value: 2,
    label: "其他"
  }
]
/***************/
//财务
/***************/

//财务状态

const FINANCE_STATUS = [
  {
    value: 0,
    label: "结算"
  },
  {
    value: 1,
    label: "确认"
  },
  {
    value: 2,
    label: "结清"
  }
]

/***************/
//任务
/***************/

//任务状态
const TASK_STATUS = [
  {
    value: 0,
    label: "待安排"
  },
  {
    value: 1,
    label: "已安排"
  },
  {
    value: 2,
    label: "待审核"
  },
  {
    value: 3,
    label: "完成"
  },
  {
    value: 4,
    label: "TEST"
  },
  {
    value: 5,
    label: "取消"
  },
  {
    value: 6,
    label: "TEST完成"
  },
  {
    value: 7,
    label: "已推荐"
  }
]

//专家打款状态

const EXPERT_PAYMENT_STATUS = [
  {
    value: 0,
    label: "申请"
  },
  {
    value: 1,
    label: "付款中"
  },
  {
    value: 2,
    label: "已付款"
  }
]

//专家履历的至今

const EXPERT_RESUME_END = [
  {
    value: 0,
    label: "至今"
  }
]

// 到款单状态
const CUSETOMER_PAYMENT_STATUS = [
  {
    value: 0,
    label: '未确认'
  },
  {
    value: 1,
    label: '已确认'
  },
  {
    value: 2,
    label: '结清'
  }
]

// 发票类型
const INVOICE_TYPE = [
  {
    value: 0,
    label: '普票'
  },
  {
    value: 1,
    label: 'PDF'
  },
  {
    value: 2,
    label: '专票'
  }
]

// 联系人是否有效

const LINKMAN_VALID = [
  {
    value: 0,
    label: '无效'
  },
  {
    value: 1,
    label: '有效'
  }
]

// 联系人是否默认

const LINKMAN_IS_DEFAULT = [
  {
    value: 0,
    label: '非默认'
  },
  {
    value: 1,
    label: '默认'
  }
]

// 联系人是否是负责人

const LINKMAN_IS_ADMIN = [
  {
    value: 0,
    label: '非负责人'
  },
  {
    value: 1,
    label: '负责人'
  }
]
// 联系人是否财务

const LINKMAN_IS_FINANCE = [
  {
    value: 0,
    label: '非财务'
  },
  {
    value: 1,
    label: '财务'
  }
]

// 联系人是否法务
const LINKMAN_IS_LAW = [
  {
    value: 0,
    label: '非法务'
  },
  {
    value: 1,
    label: '法务'
  }
]

// 专家银行账户是否默认银行卡is_abroad

const EXPERT_BANK_IS_DEFAULT = [
  {
    value: 0,
    label: '非默认'
  },
  {
    value: 1,
    label: '默认'
  }
]

// 专家银行账户是否国外账户

const EXPERT_BANK_IS_ABROAD = [
  {
    value: 0,
    label: '非默认'
  },
  {
    value: 1,
    label: '默认'
  }
]

// 操作记录
const OPERATION_TYPE = [
  {
    value: 0,
    label: '读取'
  },
  {
    value: 1,
    label: '增加'
  },
  {
    value: 2,
    label: '更新'
  },
  {
    value: 3,
    label: '删除'
  }
]

// 操作记录表的枚举

const OPERATION_TARGET = {
  log_record: {
    label: 'warningBar和沟通记录'
  },
  bus_client_info: {
    label: '客户信息'
  },
  bus_expert_info: {
    label: '专家信息'
  },
  bus_client_contract: {
    label: '客户合同'
  },
  bus_client_linkman: {
    label: '客户联系人'
  },
  bus_expert_bank: {
    label: '专家银行账户'
  },
  bus_client_supporter: {
    label: '客户经理'
  },
  bus_project_task: {
    label: '项目总应收'
  },
  fin_client_bill: {
    label: '客户总应收'
  },
  bus_expert_resume_detail: {
    label: '专家履历'
  }
}

// 各页面字段中文名称
const matchField = (target, filed) => {
  if (target === 'bus_client_info') {
    switch (filed) {
      case 'name':
        return '公司名称'
      case 'alias':
        return '别名'
      case 'english_name':
        return '英文名'
      case 'address':
        return '地址'
      case 'type':
        return '类型'
      case 'status':
        return '状态'
      case 'client_alert':
        return '客户提醒'
      case 'memo':
        return '备注'
      case 'client_reminder':
        return '客户意见'
    }
  }
  if (target === 'bus_client_contract') {
    switch (filed) {
      case 'interior_code':
        return '合同编号'
      case 'status':
        return '状态'
      case 'main':
        return '我方主体'
      case 'type':
        return '合同类型'
      case 'unit_price':
        return '单价'
      case 'total':
        return '总价'
      case 'currency':
        return '货币'
      case 'name':
        return '货币'
    }
  }
  if (target === 'bus_client_linkman') {
    switch (filed) {
      case 'family_name':
        return '姓'
      case 'given_name':
        return '名'
      case 'valid':
        return '有效'
      case 'gender':
        return '性别'
      case 'title':
        return '头衔'
      case 'main_phone':
        return '手机号'
      case 'email':
        return '邮箱'
      case 'land_line':
        return '座机'
      case 'im':
        return 'QQ/微信'
      case 'industry_name':
        return '行业'
      case 'region':
        return '地区'
      case 'is_default':
        return '首要联系人'
      case 'is_admin':
        return '负责人'
      case 'is_law':
        return '法务'
      case 'is_finance':
        return '财务'
      case 'memo':
        return '备注'
    }
  }
  if (target === 'bus_expert_info') {
    switch (filed) {
      case 'name':
        return '姓名'
      case 'industry_name':
        return '行业'
      case 'fee':
        return '费用'
      case 'currency':
        return '货币'
      case 'company':
        return '任职公司'
      case 'title':
        return '职位'
      case 'experience':
        return '个人经历'
      case 'speciality':
        return '特长'
      case 'gender':
        return '性别'
      case 'landline':
        return '座机'
      case 'phone':
        return '手机'
      case 'email':
        return 'Email'
      case 'wechat':
        return '微信'
      case 'qq':
        return 'QQ'
      case 'local':
        return '所在地'
      case 'source':
        return '来源'
      case 'referrer':
        return '推荐人'
      case 'comment':
        return 'General Comment'
      case 'other_cooperation':
        return '其他合作'
    }
  }
  if (target === 'bus_expert_bank') {
    switch (filed) {
      case 'name':
        return '姓名'
      case 'is_default':
        return '首选账户'
      case 'is_abroad':
        return '国外账户'
      case 'id_type':
        return '证件类型'
      case 'id_number':
        return '证件号'
      case 'type':
        return '账户类型'
      case 'account_of_bank':
        return '开户行'
      case 'account':
        return '账户'
      case 'memo':
        return '备注'
    }
  }
  if (target === 'bus_expert_resume_detail') {
    switch (filed) {
      case 'company':
        return '公司名'
      case 'company_en':
        return '公司名英文'
      case 'title':
        return '职位名'
      case 'title_en':
        return '职位名英文'
      case 'duty':
        return '职责'
      case 'duty_en':
        return '职责英文'
      case 'begin_time':
        return '开始时间'
      case 'now_date':
        return '结束时间'
    }
  }
}

// 专家合作枚举
const EXPERT_OTHER_COOPERATION = [
  {
    value: 0,
    label: "公开路演",
    field:'public_roadshow'
  },
  {
    value: 1,
    label: "词条共建",
    field:'entry_co_construction'
  },
  {
    value: 2,
    label: "其他合作",
    field:'other'
  },
  {
    value: 3,
    label: "否",
    field:'no_cooperation'
  }
]

const getLabel = (num, arr) => {
  for (let i in arr) {
    if (Number(arr[i].value) == Number(num)) {
      return arr[i].label
    }
  }
}

// 记录状态日志的枚举方法
const statusArr = ['type', 'status', 'source', 'gender', 'currency', 'valid', 'is_default', 'is_admin', 'is_law', 'is_finance', 'is_abroad', 'id_type','other_cooperation']
const matchTarget = (target, filed, value) => {
  if (target === 'bus_client_info') {
    switch (filed) {
      case 'type':
        return CLIENT_TYPE[value].label
      case 'status':
        return CLIENT_STATUS[value].label
    }
  }
  if (target === 'bus_expert_info') {
    switch (filed) {
      case 'source':
        return EXPERT_SOURCE[value].label
      case 'gender':
        return GENDER[value].label
      case 'currency':
        return CURRENCY[value].label
      case 'other_cooperation':
        let str = ''
        EXPERT_OTHER_COOPERATION.forEach((item) => {
          if(value.includes(item.value)){
            str += str === '' ? item.label : ',' + item.label
          }
        })
        return str
    }
  }
  if (target === 'bus_client_contract') {
    switch (filed) {
      case 'type':
        return CLIENT_CONTRACT_TYPE[value].label
      case 'status':
        return CLIENT_STATUS[value].label
      case 'currency':
        return CURRENCY[value].label
    }
  }
  if (target === 'bus_client_linkman') {
    switch (filed) {
      case 'gender':
        return GENDER[value].label
      case 'valid':
        return LINKMAN_VALID[value].label
      case 'is_default':
        return LINKMAN_IS_DEFAULT[value].label
      case 'is_admin':
        return LINKMAN_IS_DEFAULT[value].label
      case 'is_law':
        return LINKMAN_IS_DEFAULT[value].label
      case 'is_finance':
        return LINKMAN_IS_DEFAULT[value].label
    }
  }
  if (target === 'bus_expert_bank') {
    switch (filed) {
      case 'is_default':
        return EXPERT_BANK_IS_DEFAULT[value].label
      case 'is_abroad':
        return EXPERT_BANK_IS_ABROAD[value].label
      case 'id_type':
        return BANK_ID_TYPE[value].label
      case 'type':
        return BANK_ACCOUNT_TYPE[value].label
    }
  }

}

export default {
  Own_TYPE,
  CLIENT_TYPE,
  CLIENT_STATUS,
  LINKMAN_ROLE,
  LINKMAN_CHARACTER,
  CLIENT_CONTRACT_TYPE,
  CONTRACT_TYPE,
  CONTRACT_STATUS,
  CURRENCY,
  PROJECT_STATUS,
  CONVERSATION_TYPE,
  EXPERT_SOURCE,
  EXPERT_CONTACT,
  BANK_ID_TYPE,
  BANK_ACCOUNT_TYPE,
  FINANCE_STATUS,
  TASK_STATUS,
  EXPERT_PAYMENT_STATUS,
  CONTRACT_STATUS,
  PROJECT_TYPE,
  GENDER,
  EXPERT_RESUME_END,
  CUSETOMER_PAYMENT_STATUS,
  INVOICE_TYPE,
  OPERATION_TYPE,
  OPERATION_TARGET,
  LINKMAN_VALID,
  LINKMAN_IS_DEFAULT,
  LINKMAN_IS_ADMIN,
  LINKMAN_IS_FINANCE,
  LINKMAN_IS_LAW,
  EXPERT_BANK_IS_DEFAULT,
  EXPERT_BANK_IS_ABROAD,
  statusArr,
  EXPERT_OTHER_COOPERATION,
  getLabel,
  matchTarget,
  matchField
}
