const jwt = require('jsonwebtoken')
const passport = {
  getInfo () {
    if (sessionStorage.passport === 'undefined' ||
      sessionStorage.passport === '' ||
      sessionStorage.passport === null) {
      return false
    } else {
      return sessionStorage.passport
    }
  },
  setInfo: (info) => {
    sessionStorage.passport = info
  },
  getUser () {
    return jwt.decode(this.getInfo())
  },
  headers: () => {
    return { Authorization: 'Bearer ' + passport.getInfo() }
  }
}

export default passport
