import passport from './passport'

const PROJECT_MENU = [
  {
    id: 1,
    index: '/Project/Info',
    title: '项目信息',
    level: 2
  }, {
    id: 2,
    index: '/Project/LinkMan',
    title: '项目联系人',
    level: 2
  }, {
    id: 3,
    index: '/Project/Contract',
    title: '关联合同',
    level: 2
  }, {
    id: 4,
    index: '/Project/Task',
    title: '访谈',
    level: 2
  }, {
    id: 5,
    index: '/Project/Admin',
    title: '项目管理人员',
    level: 2
  }
]

const MAIN_MENU = [
  {
    id: 1,
    index: '/Home',
    title: '首页',
    level: 2
  },
  {
    id: 1,
    index: 'Client',
    title: '客户',
    level: 1,
    children: [{
      index: '/ClientList',
      title: '我的客户',
      level: 2
    },
    {
      index: '/AllClient',
      title: '所有客户',
      level: 2,
      roles: -1
    }]
  }, {
    id: 2,
    index: '/ExpertList',
    level: 2,
    title: '专家'
  }, {
    id: 3,
    index: 'Project',
    level: 1,
    title: '项目',
    children: [{
      title: '项目列表',
      index: '/ProjectList',
      level: 2
    }, {
      title: '任务清单',
      index: '/TaskList',
      level: 2,
      roles: 2
    }]
  }, {
    id: 4,
    index: 'User',
    level: 1,
    title: '财务',
    roles: 2,
    children: [{
      title: '到款单',
      index: '/ClientCheck',
      level: 2
    }, {
      title: '账单列表',
      index: '/Bill',
      level: 2
    }, {
      title: '专家付款',
      index: '/ExpertPay',
      level: 2
    }]
  }, {
    id: 5,
    title: '系统',
    index: 'System',
    level: 1,
    children: [{
      title: '邮件模板',
      index: '/emailTemplate',
      level: 2,
      roles: 3
    }, {
      title: '我的签名档',
      index: '/hSign',
      level: 2
    }]
  },
  {
    id: 1,
    index: '/login',
    title: '退出',
    level: 2
  }
]

const CLIENT_MENU = [
  {
    id: 1,
    index: '/Client/Info',
    title: '客户信息',
    level: 2
  }, {
    id: 2,
    index: '/Client/Contract',
    title: '客户合同',
    level: 2
  }, {
    id: 3,
    index: '/Client/Linkman',
    title: '客户联系人',
    level: 2
  }, {
    id: 4,
    index: '/Client/Project',
    title: '客户项目',
    level: 2
  }
]

const EXPERT_MENU = [
  {
    id: 1,
    index: '/Expert/Info',
    title: '专家信息',
    level: 2
  },
  {
    id: 3,
    index: '/Expert/Bank',
    title: '专家银行账户',
    level: 2
  }, {
    id: 4,
    index: '/Expert/Task',
    title: '专家访谈',
    level: 2
  }
]
// 根据权限计算导航栏 (普通：0  PM：1  财务：2  总监：3)
function getMenu (key = 'Home') {
  let roles = (passport.getUser() && passport.getUser().roles) || ''
  let testRoles = -1
  if (roles.indexOf('试用') > -1) testRoles = 0

  if (key.indexOf('/Project/') > -1) {
    return PROJECT_MENU
  } else if (key.indexOf('/Client/') > -1) {
    return CLIENT_MENU
  } else if (key.indexOf('/Expert/') > -1) {
    let expertMenu = EXPERT_MENU
    if (testRoles === 0) {
      expertMenu = expertMenu.filter(r => r.id === 1)
    }
    return expertMenu
  } else {
    let menu = []

    if (roles.indexOf('总监') > -1) roles = 3
    else if (roles.indexOf('财务') > -1) roles = 2
    else roles = 1

    const menuList = JSON.parse(JSON.stringify(MAIN_MENU))
    menu = menuList.filter(r => r.roles ? r.roles <= roles : true)
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].children) {
        menu[i].children = menu[i].children.filter(r => r.roles ? r.roles <= roles : true)
      }
    }
    if (testRoles === 0 && roles === 1) {
      menu = menu.filter(r => r.id !== 3 && r.id !== 5)
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].children) {
          menu[i].children = menu[i].children.filter(r => r.title !== '所有客户')
        }
      }
    }
    return menu
  }
}

export default { getMenu }
