import Vue from 'vue'
const EventBus = new Vue()
export const EventBridge = {
  eventName: {
    getSubViews: 'getSubViews'
  },
  on (name, fn) {
    EventBus.$on(name, fn)
  },
  emit (name, data) {
    EventBus.$emit(name, data)
  },
  offOne (name) {
    EventBus.$off(name)
  },
  // 移除所有的时间监听
  offAll () {
    EventBus.$off()
  },
  getEventName (key, value) { // value是没有取到值时的默认值
    return this.eventName[key] + value
  }
}
