<template lang="pug">
  .edit-input
    el-row
      el-col(:span="20")
        el-autocomplete.wd-100(
          :size="GLOBAL.SIZE_OF_INPUTBOX"
          class="inline-input"
          v-model="newValue"
          style="width:100%"
          :fetch-suggestions="querySearch"
          :placeholder="placeholder"
          :trigger-on-focus="false"
          clearable
          :disabled="isEditor"
          @select="handleSelect")
      el-col(:span="1")
        .cursor(v-if="exit")
          i.el-icon-edit-outline(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="isEditor" @click="isAllow")
          i.el-icon-circle-check(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="!isEditor" @click="save")

</template>

<script>
// import apis from '@/apis/finance.js'
export default {
  name: 'EditAutocomplete',
  props: {
    value: {},
    isEditorInput: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    exit: {
      type: Boolean,
      default: true
    },
    keyName: {
      type: String,
      default: 'name'
    },
    // 要远程的url
    url: String
  },
  data () {
    return {
      isEditor: true,
      timeout: null,
      selected: {},
      receiveInput: [],
      beforeUpdate: true
    }
  },
  created () {
    this.isEditor = this.isEditorInput
  },
  computed: {
    newValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    isEditorInput: {
      handler: function (val) {
        if (val) {
          this.isEditor = val
        }
      },
      immediate: true
    },
    exit: {
      handler: function (val) {
        if (!val) this.isEditor = val
      },
      immediate: true
    }
  },
  methods: {
    isAllow () {
      this.$emit('update:isEditorInput', false)
      this.isEditor = !this.isEditor
      this.$emit('editing')
    },
    handleSelect (item) {
      this.selected = item
      this.$emit('autoSelect', item)
    },
    querySearch (queryString, cb) {
      this.$emit('querySearch', this.keyName, queryString, run => {
        cb(this.receiveInput)
      })
    },
    save () {
      this.$emit('save', this.keyName, this.newValue, run => {
        if (this.beforeUpdate) {
          this.isEditor = !this.isEditor
        }
      })
    },
    changeStatus (val) {
      this.isEditor = val
    }
  }
}
</script>

<style scoped>
.el-icon-edit-outline {
  padding: 7px;
}
.cursor {
  cursor: pointer;
  color: #666666;
  font-size: 1.5em;
}

/* disable 后改变字体颜色 */
:deep() input:disabled,:deep() textarea:disabled {
  opacity: 0.7;
  -webkit-text-fill-color: black;
}
</style>
