import DICT from '@/apis/dict'

// 客户类型
export function clientType (val) {
  return DICT.getLabel(val, DICT.CLIENT_TYPE)
}

// 客户状态
export function clientStatus (val) {
  return DICT.getLabel(val, DICT.CLIENT_STATUS)
}

// 联系人头衔
export function linkManCharacter (val) {
  return DICT.getLabel(val, DICT.LINKMAN_CHARACTER)
}

// 联系人角色
export function linkManRole (val) {
  return DICT.getLabel(val, DICT.LINKMAN_ROLE)
}

// 合同类型
export function contractType (val) {
  return DICT.getLabel(val, DICT.CONTRACT_TYPE)
}

// 客户合同类型
export function customerContractType (val) {
  return DICT.getLabel(val, DICT.CUSTOMER_CONTRACT_TYPE)
}

// 客户合同状态
export function contractStatus (val) {
  return DICT.getLabel(val, DICT.CONTRACT_STATUS)
}

// 货币
export function currency (val) {
  return DICT.getLabel(val, DICT.CURRENCY)
}

// 项目状态
export function projectStatus (val) {
  return DICT.getLabel(val, DICT.PROJECT_STATUS)
}

// 项目类型
export function projectType (val) {
  return DICT.getLabel(val, DICT.PROJECT_TYPE)
}

// 访谈方式
export function conversationType (val) {
  return DICT.getLabel(val, DICT.CONVERSATION_TYPE)
}

// 专家来源
export function expertSource (val) {
  return DICT.getLabel(val, DICT.EXPERT_SOURCE)
}

// 专家联系方式
export function expertContact (val) {
  return DICT.getLabel(val, DICT.EXPERT_CONTACT)
}

// 专家联系方式
export function gender (val) {
  return DICT.getLabel(val, DICT.GENDER)
}

// 证件类型
export function bankIdType (val) {
  return DICT.getLabel(val, DICT.BANK_ID_TYPE)
}

// 银行账户类型
export function bankAccountType (val) {
  return DICT.getLabel(val, DICT.BANK_ACCOUNT_TYPE)
}

// 财务状态
export function financeStatus (val) {
  return DICT.getLabel(val, DICT.FINANCE_STATUS)
}

// 任务状态
export function taskStatus (val) {
  return DICT.getLabel(val, DICT.TASK_STATUS)
}

// 专家付款状态
export function expertPaymentStatus (val) {
  return DICT.getLabel(val, DICT.EXPERT_PAYMENT_STATUS)
}
