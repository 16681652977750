<!--
  xfs  2021-5-24 输入框组件（其他组件参数一致，同此组件）
  newValue：传入的model值
  isEditor:是否处于编辑状态
  isAllow:是否有权限编辑
  exit：false代表新建，不显示编辑按钮，true表示存在即要编辑
  keyName：当前输入框的字段名
 -->
<template lang="pug">
  .edit-input
    el-row
      el-col(:span="20")
        el-input.wd-100(v-model="newValue" :maxlength="maxlength" :type="type" @blur="handleblur()" :disabled="isEditor" :rows="rows" :placeholder="placeholder" :autosize="autosize"  :size="GLOBAL.SIZE_OF_INPUTBOX" @keyup.enter.ctrl.native='handleKeydown')
      el-col(:span="1")
        .cursor(v-if="exit")
          i.el-icon-edit-outline(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="isEditor" @click="isAllow")
          i.el-icon-circle-check(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="!isEditor" @click="save")
</template>

<script>
export default {
  name: 'EditInput',
  props: {
    value: {},
    isEditorInput: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: Number,
      default: 3
    },
    maxlength: {
      type: Number,
      default: 99999
    },
    exit: {
      type: Boolean,
      default: true
    },
    keyName: {
      type: String
    },
    params: {
      type: Object,
      default: () => {}
    },
    span: {
      type: String,
      default: '20'
    },
    autosize: { minRows: 3, maxRows: 8 }
  },
  data () {
    return {
      isEditor: true,
      beforeUpdate: true
    }
  },
  created () {
    this.isEditor = this.isEditorInput
  },
  computed: {
    newValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    // 检测输入框值的变化
    isEditorInput: {
      handler: function (val) {
        if (val) {
          this.isEditor = val
        }
      },
      immediate: true
    },
    // 检测exit的变化
    exit: {
      handler: function (val) {
        if (!val) this.isEditor = val
      },
      immediate: true
    }
  },
  methods: {
    // 是否允许修改
    isAllow () {
      this.$emit('update:isEditorInput', false)
      this.isEditor = !this.isEditor
      this.$emit('editing')
    },
    handleblur () {
      this.$emit('handleblur', this.keyName, this.newValue)
    },
    // 点击保存提交到父组件处理
    save () {
      this.$emit('save', this.keyName, this.newValue, run => {
        if (this.beforeUpdate) {
          this.isEditor = !this.isEditor
        }
      }, this.params)
    },
    change (val) {
      this.isEditor = val
    },
    handleKeydown (e) {
      this.isEditor = !this.isEditor
      this.$emit('save', this.keyName, this.newValue)
    }

  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
  color: #666666;
  font-size: 1.5em;
}
:deep() input:disabled,:deep() textarea:disabled {
  opacity: 0.7;
  -webkit-text-fill-color: black;
}
</style>
