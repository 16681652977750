<template lang="pug">
  .edit-rage
    el-row
      el-col(:span="6")
        el-rate(v-model="newValue" :disabled="isEditor" :size="GLOBAL.SIZE_OF_INPUTBOX")
      el-col(:span="1")
        .cursor(v-if="exit")
          i.el-icon-edit-outline(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="isEditor" @click="isAllow")
          //- i.el-icon-circle-check(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="!isEditor" @click="save")

</template>

<script>
export default {
  name: 'EditRate',
  props: {
    value: {},
    isEditorInput: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    exit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isEditor: true
    }
  },
  created () {
    this.isEditor = this.isEditorInput
  },
  computed: {
    newValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    isEditorInput: {
      handler: function (val) {
        this.isEditor = val
      },
      immediate: true
    }
  },
  methods: {
    isAllow () {
      this.$emit('update:isEditorInput', false)
      this.$emit('editing')
      this.isEditor = !this.isEditor
    },
    handleblur (data) {
      this.$emit('handleblur', data)
    }
  }
}
</script>

<style scoped>
.el-icon-edit-outline {
  padding: 7px;
}
.cursor {
  cursor: pointer;
  color: #666666;
  font-size: 1.5em;
}
</style>
