<template lang="pug">
  .edit-input
    el-row
      el-col(:span="20")
        el-radio(v-for="item in dict" v-model="newValue"
          :key="item.value" :label="item.value" :disabled="isEditor") {{ item.label }}
      el-col(:span="2")
        .cursor(v-if="exit")
          i.el-icon-edit-outline(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="isEditor" @click="isAllow")
          i.el-icon-circle-check(:size="GLOBAL.SIZE_OF_INPUTBOX" v-if="!isEditor" @click="save")

</template>

<script>
export default {
  name: 'EditRadio',
  props: {
    value: {},
    isEditorInput: {
      type: Boolean,
      default: true
    },
    dict: Array,
    exit: {
      type: Boolean,
      default: true
    },
    keyName: {
      type: String
    }
  },
  data () {
    return {
      isEditor: true,
      beforeUpdate: true
    }
  },
  created () {
    this.isEditor = this.isEditorInput
  },
  computed: {
    newValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    isEditorInput: {
      handler: function (val) {
        if (val) {
          this.isEditor = val
        }
      },
      immediate: true
    },
    exit: {
      handler: function (val) {
        if (!val) this.isEditor = val
      },
      immediate: true
    }
  },
  methods: {
    isAllow () {
      this.$emit('update:isEditorInput', false)
      this.isEditor = !this.isEditor
      this.$emit('editing')
    },
    change () {
      this.$emit('change')
    },
    save () {
      this.$emit('save', this.keyName, this.newValue, run => {
        if (this.beforeUpdate) {
          this.isEditor = !this.isEditor
        }
      })
    }
  }
}
</script>

<style scoped>
/* .el-icon-edit-outline {
  padding: 7px;
} */
.cursor {
  cursor: pointer;
  color: #666666;
  font-size: 1.5em;
}
</style>
