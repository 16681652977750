<template lang="pug">
#app
  div(v-if="this.$route.path !== '/login'" style="z-index: 999; position: relative;" @mouseover="enter" @mouseout="level")
    el-header.header()
      el-menu(@select="handleNavigate" mode="horizontal" background-color="#545c64"
              text-color="#bbbbbb" active-text-color="#ffffff" :default-active="$route.path")
        template(v-for="item in subMenu")
          el-submenu(v-if="item.level === 1" :index='item.index')
            template(slot='title') {{ item.title }}
            template( v-for="ite in item.children")
              el-menu-item(:index="ite.index") {{ ite.title }}
          el-menu-item(v-else-if="item.level === 2" :index='item.index') {{ item.title }}
          //- el-menu-item(@click="logOut" index='') 退出
  transition(mode="out-in")
    router-view(:key="this.$route.path")

</template>

<script>
import subMenu from '@/apis/subViews'
import { EventBridge } from '@/utils/event-bus.js'
export default {
  data () {
    return {
      subMenu: [],
      isHidden: false,
      sHeight: 60
    }
  },
  created () {
    const path = window.location.hash.replace('#', '')
    this.subMenu = subMenu.getMenu(path)
    EventBridge.on('getSubViews', () => {
      this.subMenu = subMenu.getMenu(this.$route.path)
    })
  },
  methods: {
    handleNavigate (key) {
      const keys = Object.keys(this.$route.query)
      const ignore = ['/', '/Home']
      let path = key
      if (ignore.indexOf(key) === -1 && keys.length !== 0) {
        path = keys.map(item => { return item + `=${this.$route.query[item]}` })
        path = path.join('&')
        path = `${key}?${path}`
      }
      if (key.indexOf('/') >= 0) this.$router.push(path)
    },
    logOut () { },
    enter () {
      if (this.isHidden) {
        this.sHeight = 60
      }
    },
    level () {
      if (this.isHidden) {
        this.sHeight = 10
      }
    }
  },
  watch: {
    $route: function (index) {
      if (index) {
        this.subMenu = subMenu.getMenu(index.path)
        const reg = /(Home|Main)/
        if (reg.test(index.name)) {
          this.isHidden = true
          this.sHeight = 10
        } else {
          this.isHidden = false
          this.sHeight = 60
        }
      }
    }
  }
}
</script>
<style scoped>
.header{
  overflow-x: auto;
  overflow-y: hidden;
  transition: 1s;
}
.el-header {
  background-color: #545c64;
  color: #fff;
  margin: 0 0 0px 0;
  padding: 0 10px;
}
.el-menu--horizontal.el-menu {
  min-width: 600px;
}
</style>

<style>
#content {
  height: 100%;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transform: translateX(0);
  transition: all 0.5s ease;
}

.go-info {
  min-width: 40px;
  /* height: 20px; */
  display: block;
  text-decoration: underline;
  color: #409EFF;
  cursor: pointer;
}
</style>
